import { Button, useTheme, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import './Pantry.css';
function Pantry() {
    const dispatch = useDispatch();
    const userIngredients = useSelector(store => store.userIngredientsReducer);
    const [expandedSection, setExpandedSection] = useState(null);
    const vegetablesAndGreens = [
        "Scallion",
        "Red onion",
        "Celery",
        "Carrot",
        "Jalapeño",
        "Avocado",
        "Zucchini",
        "Shallot",
        "Garlic",
        "Cherry tomato",
        "Cucumber",
        "Spinach",
        "Corn",
        "Chile pepper",
        "Sweet potato",
        "Russet potato",
        "Baby potatoes",
        "Broccoli",
        "Baby greens",
        "Pumpkin",
        "Cauliflower",
        "Tomato",
        "Asparagus",
        "Cabbage",
        "Arugula",
        "Bell pepper",
        "Leek",
        "Kale",
        "Eggplant",
        "Lettuce",
        "Butternut squash",
        "Romaine",
        "Beetroot",
        "Brussels sprout",
        "Fennel",
        "Sun-dried tomato",
        "Radish",
        "Red cabbage",
        "Artichoke",
        "Summer squash",
        "Mixed greens",
        "Parsnip",
        "Baby carrot",
        "Mixed vegetable",
        "Poblano pepper",
        "Sweet pepper",
        "Green tomato",
        "Serrano pepper",
        "Watercress",
        "Iceberg lettuce",
        "Mashed potatoes",
        "Horseradish",
        "Chard",
        "Hash browns",
        "Pimento",
        "Napa cabbage",
        "Butter lettuce",
        "Spaghetti squash",
        "Coleslaw",
        "Celeriac",
        "Turnip",
        "Bok choy",
        "Okra",
    ];

    const mushrooms = [
        "Button mushroom",
        "Shiitake mushroom",
        "Portobello mushroom",
        "Wild mushroom",
        "Porcini mushroom",
        "Oyster mushroom",
        "Cremini mushroom",
        "Mixed mushrooms",
        "Chestnut mushroom",
        "Chanterelle mushroom",
        "Enoki mushroom",
        "Morel mushroom",
        "Black fungus mushroom",
        "Black truffle mushroom",
        "Field mushroom",
        "King oyster mushroom",
        "Straw mushroom",
        "Wood ear mushroom",
        "Cordyceps mushroom",
        "Reishi mushroom",
        "Chaga mushroom",
        "Lion's Mane mushroom",
    ];
    const fruits = [
        "Apple",
        "Banana",
        "Orange",
        "Strawberry",
        "Blueberry",
        "Raspberry",
        "Blackberry",
        "Cherry",
        "Grape",
        "Watermelon",
        "Cantaloupe",
        "Honeydew",
        "Pineapple",
        "Mango",
        "Peach",
        "Nectarine",
        "Plum",
        "Apricot",
        "Kiwi",
        "Gold kiwi",
        "Pear",
        "Pomegranate",
        "Papaya",
        "Grapefruit",
        "Lemon",
        "Lime",
        "Coconut",
        "Cranberry",
        "Gooseberry",
        "Lychee",
        "Passionfruit",
        "Guava",
        "Dragonfruit",
        "Clementine",
        "Mandarin",
        "Fig",
        "Date",
        "Prune",
        "Rhubarb",
        "Raisin",
    ];

    const nutsAndSeeds = [
        "Almond",
        "Peanut",
        "Walnut",
        "Cashew",
        "Pistachio",
        "Hazelnut",
        "Pecan",
        "Macadamia",
        "Brazil nut",
        "Chestnut",
        "Pine nut",
        "Sunflower seed",
        "Pumpkin seed",
        "Sesame seed",
        "Chia seed",
        "Flaxseed",
        "Hemp seed",
        "Poppy seed",
        "Fenugreek seed",
        "Mustard seed",
        "Caraway seed",
        "Fennel seed",
        "Anise seed",
        "Celery seed",
        "Cumin seed",
        "Coriander seed",
        "Quinoa",
        "Amaranth",
        "Millet",
        "Buckwheat",
        "Barley",
    ];

    const cheeses = [
        "Cheddar",
        "Mozzarella",
        "Parmesan",
        "Brie",
        "Swiss",
        "Gouda",
        "Feta",
        "Provolone",
        "Monterey Jack",
        "Colby",
        "Blue cheese",
        "Camembert",
        "Ricotta",
        "Cream cheese",
        "Goat cheese",
        "Havarti",
        "Gruyère",
        "Asiago",
        "Manchego",
        "Pecorino Romano",
        "Emmental",
        "Mascarpone",
        "Roquefort",
        "Gorgonzola",
        "Fontina",
        "Queso blanco",
        "Cottage cheese",
        "Paneer",
        "Halloumi",
        "Queso fresco",
        "Oaxaca",
        "Aged gouda",
        "Brick cheese",
        "Colby-Jack",
        "Pepper Jack",
        "Queso de bola",
        "Queso Chihuahua",
    ];

    const meats = [
        "Beef",
        "Ground beef",
        "Beef steak",
        "Beef roast",
        "Beef ribs",
        "Beef brisket",
        "Beef tenderloin",
        "Corned beef",
        "Beef jerky",
        "Veal",
        "Pork",
        "Ground pork",
        "Pork chops",
        "Pork roast",
        "Pork ribs",
        "Pork belly",
        "Ham",
        "Bacon",
        "Prosciutto",
        "Pancetta",
        "Salami",
        "Pepperoni",
        "Sausage",
        "Chicken",
        "Chicken breast",
        "Chicken thigh",
        "Chicken wings",
        "Chicken drumsticks",
        "Chicken liver",
        "Chicken sausage",
        "Turkey",
        "Ground turkey",
        "Turkey breast",
        "Turkey legs",
        "Turkey wings",
        "Turkey sausage",
        "Duck",
        "Duck breast",
        "Duck legs",
        "Duck liver",
        "Duck confit",
        "Goose",
        "Goose breast",
        "Goose legs",
        "Goose liver",
        "Rabbit",
        "Rabbit legs",
        "Venison",
        "Venison steak",
        "Venison sausage",
        "Bison",
        "Bison steak",
        "Bison burger",
        "Lamb",
        "Lamb chops",
        "Lamb roast",
        "Lamb shank",
        "Ground lamb",
        "Lamb sausage",
        "Goat",
        "Goat stew",
        "Quail",
        "Pheasant",
        "Buffalo",
        "Buffalo steak",
        "Buffalo burger",
        "Wild boar",
        "Boar sausage",
        "Kangaroo",
        "Elk",
        "Elk steak",
        "Elk burger",
        "Alligator",
        "Alligator sausage",
        "Frog legs",
    ];

    const meatAndDairySubstitutes = [
        "Tofu",
        "Tempeh",
        "Seitan",
        "Jackfruit",
        "Beyond Meat",
        "Impossible Burger",
        "Kidney beans",
        "Nutritional yeast",
        "Almond milk",
        "Soy milk",
        "Oat milk",
        "Rice milk",
        "Cashew milk",
        "Hemp milk",
        "Pea milk",
        "Coconut yogurt",
        "Almond yogurt",
        "Soy yogurt",
        "Oat yogurt",
        "Rice yogurt",
        "Cashew yogurt",
        "Hemp yogurt",
        "Coconut ice cream",
        "Almond ice cream",
        "Soy ice cream",
        "Oat ice cream",
        "Rice ice cream",
        "Cashew ice cream",
        "Hemp ice cream",
    ];

    const seafood = [
        "Salmon",
        "Smoked salmon",
        "Tuna",
        "Cod",
        "Haddock",
        "Trout",
        "Halibut",
        "Mackerel",
        "Sardines",
        "Anchovies",
        "Snapper",
        "Sea bass",
        "Grouper",
        "Swordfish",
        "Tilapia",
        "Flounder",
        "Catfish",
        "Pollock",
        "Scallops",
        "Shrimp",
        "Prawns",
        "Lobster",
        "Crab",
        "King crab",
        "Snow crab",
        "Dungeness crab",
        "Blue crab",
        "Stone crab",
        "Crawfish",
        "Oysters",
        "Clams",
        "Mussels",
        "Octopus",
        "Squid",
        "Calamari",
        "Eel",
        "Albacore",
        "Bigeye tuna",
        "Yellowfin tuna",
        "Bluefin tuna",
        "Mahi mahi",
    ];

    const herbsAndSpices = [
        "Salt",
        "Basil",
        "Oregano",
        "Thyme",
        "Rosemary",
        "Parsley",
        "Cilantro",
        "Dill",
        "Mint",
        "Sage",
        "Chives",
        "Tarragon",
        "Marjoram",
        "Bay leaves",
        "Lemongrass",
        "Lavender",
        "Chervil",
        "Epazote",
        "Curry leaves",
        "Paprika",
        "Turmeric",
        "Cumin",
        "Coriander",
        "Cardamom",
        "Cloves",
        "Nutmeg",
        "Cinnamon",
        "Ginger",
        "Garlic powder",
        "Onion powder",
        "Fenugreek",
        "Caraway",
        "Anise",
        "Star anise",
        "Saffron",
        "Black pepper",
        "White pepper",
        "Pink peppercorn",
        "Green peppercorn",
        "Allspice",
        "Juniper berries",
        "Cayenne pepper",
        "Chili powder",
        "Crushed red pepper",
        "Chipotle",
        "Aleppo pepper",
        "Habanero powder",
        "Smoked paprika",
        "Hungarian paprika",
        "Spanish paprika",
        "Chinese five spice",
        "Ras el hanout",
        "Harissa",
        "Sumac",
        "Za'atar",
        "Herbes de Provence",
        "Italian seasoning",
        "Poultry seasoning",
        "Old Bay seasoning",
        "Cajun seasoning",
        "Creole seasoning",
        "Taco seasoning",
        "Fajita seasoning",
        "Ranch seasoning",
        "Celery seed",
        "Poppy seeds",
        "Curry powder",
        "Dill seeds",
        "Cardamom pods",
        "Clove buds",
        "Seafood seasoning",
    ];

    const sugarsAndSweeteners = [
        "White sugar",
        "Brown sugar",
        "Raw sugar",
        "Turbinado sugar",
        "Muscovado sugar",
        "Powdered sugar",
        "Confectioners' sugar",
        "Cane sugar",
        "Beet sugar",
        "Demerara sugar",
        "Palm sugar",
        "Coconut sugar",
        "Maple sugar",
        "Maple syrup",
        "Honey",
        "Agave nectar",
        "Corn syrup",
        "Molasses",
    ];

    const bakingIngredients = [
        "All-purpose flour",
        "Bread flour",
        "Cake flour",
        "Whole wheat flour",
        "Almond flour",
        "Coconut flour",
        "Pastry flour",
        "Self-rising flour",
        "Cornmeal",
        "Cornstarch",
        "Baking powder",
        "Baking soda",
        "Yeast",
        "Buttermilk",
        "Heavy cream",
        "Half-and-half",
        "Evaporated milk",
        "Condensed milk",
        "Margarine",
        "Canola oil",
        "Coconut oil",
        "Shortening",
        "Lard",
        "Eggs",
        "Egg whites",
        "Egg yolks",
        "Vanilla extract",
        "Almond extract",
        "Lemon extract",
        "Orange extract",
        "Peppermint extract",
        "Cocoa powder",
        "Chocolate chips",
        "Baking chocolate",
        "White chocolate",
        "Milk chocolate",
        "Dark chocolate",
        "Semi-sweet chocolate",
        "Unsweetened chocolate",
        "Nutella",
    ];

    const premadeDoughsAndWrappers = [
        "Pizza dough",
        "Pie crust",
        "Croissant dough",
        "Biscuit dough",
        "Crescent roll dough",
        "Cookie dough",
        "Bread dough",
        "Tortilla",
        "Spring roll wrappers",
        "Egg roll wrappers",
        "Wonton wrappers",
        "Dumpling wrappers",
        "Gyoza wrappers",
        "Tamale wrappers",
    ];

    const grainsAndCereals = [
        "Rice",
        "Brown rice",
        "White rice",
        "Basmati rice",
        "Jasmine rice",
        "Wild rice",
        "Quinoa",
        "Barley",
        "Oats",
        "Millet",
        "Farro",
        "Bulgur",
        "Couscous",
        "Buckwheat",
        "Popcorn",
        "Cornmeal",
        "Grits",
        "Polenta",
        "Rolled oats",
        "Steel-cut oats",
        "Instant oats",
        "Quick oats",
    ];

    const legumes = [
        "Black beans",
        "Navy beans",
        "Pinto beans",
        "Kidney beans",
        "Lima beans",
        "Great Northern beans",
        "Cannellini beans",
        "Garbanzo beans",
        "Chickpeas",
        "Green beans",
        "Soybeans",
        "Edamame",
        "Lentils",
        "Red lentils",
        "Green lentils",
        "Brown lentils",
        "Yellow lentils",
        "Split peas",
        "Green split peas",
        "Yellow split peas",
        "Peanuts",
        "Black-eyed peas",
        "Fava beans",
        "Sugar snap peas",
        "Field peas",
    ];

    const pastas = [
        "Spaghetti",
        "Fettuccine",
        "Linguine",
        "Penne",
        "Rigatoni",
        "Macaroni",
        "Rotini",
        "Fusilli",
        "Orzo",
        "Farfalle",
        "Ziti",
        "Lasagna",
        "Ravioli",
        "Tortellini",
        "Gnocchi",
        "Cannelloni",
        "Manicotti",
        "Angel hair",
        "Vermicelli",
        "Tagliatelle",
        "Pappardelle",
        "Bucatini",
        "Ditalini",
        "Conchiglie",
        "Cavatappi",
        "Gemelli",
        "Soba",
        "Udon"
    ];

    const breadsAndSaltySnacks = [
        "White bread",
        "Whole wheat bread",
        "Sourdough",
        "Rye bread",
        "Pumpernickel",
        "Baguette",
        "Ciabatta",
        "Focaccia",
        "Naan",
        "Pita bread",
        "Tortilla",
        "Bagel",
        "English muffin",
        "Croissant",
        "Brioche",
        "Challah",
        "Breadsticks",
        "Croutons",
        "Pretzel",
        "Soft pretzel",
        "Crackers",
        "Ritz crackers",
        "Saltine crackers",
        "Graham crackers",
        "Cheese crackers",
        "Rice crackers",
        "Pita chips",
        "Tortilla chips",
        "Potato chips",
        "Corn chips",
        "Popcorn",
        "Cheese puffs",
        "Nachos",
        "Sun Chips",
        "Fritos",
        "Pretzel chips",
        "Bagel chips",
        "Crispbread",
        "Melba toast",
        "Breadsticks",
        "Garlic bread",
        "Cornbread",
        "Banana bread",
        "Pumpkin bread",
        "Pumpernickel bread",
        "Soda bread",
        "Flatbread",
        "Lavash",
        "Matzo",
        "Roti",
    ];

    const oilsAndFats = [
        "Olive oil",
        "Extra virgin olive oil",
        "Vegetable oil",
        "Peanut butter",
        "Almond butter",
        "Canola oil",
        "Sunflower oil",
        "Peanut oil",
        "Sesame oil",
        "Coconut oil",
        "Avocado oil",
        "Grapeseed oil",
        "Flaxseed oil",
        "Hemp oil",
        "Pumpkin seed oil",
        "Walnut oil",
        "Almond oil",
        "Hazelnut oil",
        "Macadamia oil",
        "Safflower oil",
        "Soybean oil",
        "Corn oil",
        "Rice bran oil",
        "Palm oil",
        "Butter",
        "Ghee",
        "Margarine",
        "Shortening",
        "Lard",
        "Duck fat",
        "Chicken fat",
        "Beef tallow",
        "Bacon fat",
        "Cocoa butter",
        "Shea butter",
    ];

    const dressingsAndVinegars = [
        "Ranch dressing",
        "Caesar dressing",
        "Blue cheese dressing",
        "Thousand Island dressing",
        "French dressing",
        "Italian dressing",
        "Greek dressing",
        "Balsamic vinegar",
        "Red wine vinegar",
        "White wine vinegar",
        "Apple cider vinegar",
        "Rice vinegar",
        "Malt vinegar",
        "Sherry vinegar",
        "Champagne vinegar",
        "Distilled white vinegar",
        "Aioli",
        "Tahini",
    ];

    const condiments = [
        "Ketchup",
        "Mustard",
        "Mayonnaise",
        "Relish",
        "Horseradish",
        "Hot sauce",
        "BBQ sauce",
        "Teriyaki sauce",
        "Fish sauce",
        "Sriracha",
        "Honey mustard",
        "Dijon mustard",
        "Yellow mustard",
        "Spicy brown mustard",
        "Stone ground mustard",
        "Chili paste",
        "Curry paste",
        "Miso paste",
        "Tapenade",
        "Chimichurri",
        "Pesto",
        "Tzatziki",
        "Harissa",
        "Romesco",
        "Remoulade",
        "Cocktail sauce"
    ];

    const cannedFoods = [
        "Canned tomatoes",
        "Canned corn",
        "Canned beans",
        "Canned peas",
        "Canned carrots",
        "Canned green beans",
        "Canned mushrooms",
        "Canned sweet potatoes",
        "Canned pineapple",
        "Canned peaches",
        "Canned olives",
        "Canned tuna",
        "Canned salmon",
        "Canned chicken",
        "Canned beef",
        "Canned ham",
        "Canned spam",
        "Canned sardines",
        "Canned anchovies",
        "Canned clams",
        "Canned soup",
        "Canned chili",
        "Canned stew",
        "Canned jam",
        "Canned jelly",
    ];

    const saucesSpreadsAndDips = [
        "Hummus",
        "Mayonnaise",
        "Soy sauce",
        "Worcestershire sauce",
        "Tamari",
        "Teriyaki sauce",
        "Ponzu sauce",
        "Fish sauce",
        "Oyster sauce",
        "Hoisin sauce",
        "Sweet and sour sauce",
        "Satay sauce",
        "Peanut sauce",
        "Sriracha",
        "Hot sauce",
        "BBQ sauce",
        "Tartar sauce",
        "Duck sauce",
        "Plum sauce",
        "Guacamole",
        "Salsa",
        "Pico de Gallo",
        "Ranch dip",
        "French onion dip",
        "Spinach dip",
        "Artichoke dip",
        "Buffalo chicken dip",
        "Cheese dip",
        "Queso",
        "Nacho cheese sauce",
        "Marinara sauce",
        "Alfredo sauce",
        "Pasta sauce",
        "Pizza sauce",
        "Bolognese sauce",
        "Puttanesca sauce",
        "Arrabbiata sauce",
        "Vodka sauce",
        "Carbonara sauce",
        "Hollandaise sauce",
        "Béarnaise sauce",
        "Bechamel sauce",
        "Gravy",
        "Tomato sauce",
        "Apple sauce",
        "Cranberry sauce",
        "Mango chutney",
        "Green chutney",
        "Tomato chutney",
        "Onion chutney",
        "Curry sauce",
        "Tikka masala sauce",
        "Vindaloo sauce",
        "Mango salsa",
        "Pineapple salsa",
        "Corn salsa",
        "Black bean salsa",
        "Tomatillo salsa",
        "Avocado salsa",
        "Mint sauce",
        "Blue cheese sauce",
        "Buffalo sauce",
        "Gochujang",
        "Miso",
    ];

    const wineBeerAndSpirits = [
        "Red wine",
        "White wine",
        "Rose wine",
        "Sparkling wine",
        "Champagne",
        "Prosecco",
        "Cava",
        "Port",
        "Sherry",
        "Madeira",
        "Vermouth",
        "Beer",
        "Ale",
        "Lager",
        "Stout",
        "Porter",
        "Pilsner",
        "Pale ale",
        "India Pale Ale (IPA)",
        "Wheat beer",
        "Saison",
        "Sour beer",
        "Barleywine",
        "Bitter",
        "Brown ale",
        "Amber ale",
        "Blonde ale",
        "Red ale",
        "Scotch ale",
        "Belgian ale",
        "Bock",
        "Doppelbock",
        "Eisbock",
        "Tripel",
        "Quadruple",
        "Saison",
        "Gose",
        "Lambic",
        "Fruit beer",
        "Hard cider",
        "Mead",
        "Sake",
        "Whiskey",
        "Bourbon",
        "Scotch",
        "Rye whiskey",
        "Irish whiskey",
        "Canadian whiskey",
        "Japanese whiskey"
    ];

    const beverages = [
        "Water",
        "Sparkling water",
        "Mineral water",
        "Tonic water",
        "Club soda",
        "Cola",
        "Diet cola",
        "Lemon-lime soda",
        "Root beer",
        "Ginger ale",
        "Ginger beer",
        "Orange soda",
        "Grape soda",
        "Cream soda",
        "Fruit punch",
        "Lemonade",
        "Iced tea",
        "Sweet tea",
        "Green tea",
        "Black tea",
        "Herbal tea",
        "Chamomile tea",
        "Peppermint tea",
        "Rooibos tea",
        "Earl Grey tea",
        "Chai tea",
        "Matcha",
        "Coffee",
        "Espresso",
        "Cappuccino",
        "Latte",
        "Mocha",
        "Macchiato",
        "Hot chocolate",
        "Cocoa",
        "Milk",
        "Skim milk",
        "Whole milk",
        "2% milk",
        "Soy milk",
        "Almond milk",
        "Coconut milk",
        "Oat milk",
        "Rice milk",
        "Hemp milk",
        "Eggnog",
        "Orange juice",
        "Apple juice",
        "Grape juice",
        "Cranberry juice",
        "Pineapple juice",
        "Tomato juice",
        "Carrot juice",
        "Vegetable juice",
        "Beet juice",
        "Pomegranate juice",
        "Mango juice",
        "Peach juice",
        "Papaya juice",
        "Strawberry juice",
        "Lemon juice",
        "Lime juice",
        "Grapefruit juice",
        "Watermelon juice",
        "Cucumber juice",
        "Coconut water",
        "Milkshake",
        "Kombucha",
    ];

    const supplements = [
        "Vanilla protein powder",
        "Chocolate protein powder",
        "Strawberry protein powder",
        "Banana protein powder",
        "Peanut butter protein powder",
        "Cookies and Cream protein powder",
        "Collagen",
        "Creatine",
        "Ashwagandha",
    ];

    const sections = {
        vegetablesAndGreens: vegetablesAndGreens,
        oilsAndFats: oilsAndFats,
        herbsAndSpices: herbsAndSpices,
        grainsAndCereals: grainsAndCereals,
        legumes: legumes,
        mushrooms: mushrooms,
        fruits: fruits,
        nutsAndSeeds: nutsAndSeeds,
        cheeses: cheeses,
        meats: meats,
        seafood: seafood,
        pastas: pastas,
        beverages: beverages,
        breadsAndSaltySnacks: breadsAndSaltySnacks,
        cannedFoods: cannedFoods,
        saucesSpreadsAndDips: saucesSpreadsAndDips,
        condiments: condiments,
        dressingsAndVinegars: dressingsAndVinegars,
        sugarsAndSweeteners: sugarsAndSweeteners,
        bakingIngredients: bakingIngredients,
        meatAndDairySubstitutes: meatAndDairySubstitutes,
        premadeDoughsAndWrappers: premadeDoughsAndWrappers,
        wineBeerAndSpirits: wineBeerAndSpirits,
        supplements: supplements
    };

    const toggleExpandedSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    useEffect(() => {
        dispatch({ type: 'FETCH_USER_INGREDIENTS' }); // Fetch user ingredients
    }, [dispatch]);

    const theme = useTheme();
    const primaryColor = '#df321b';
    const secondaryColor = '#4A4A4A';
    const tertiaryColor = '#A84430';  // Slightly lighter shade of primary color

    const buttonStyle = {
        margin: theme.spacing(1),
        borderRadius: '20px',
        padding: theme.spacing(1, 2),
        fontSize: '0.875rem',
        textTransform: 'none',
        boxShadow: theme.shadows[2],
        transition: theme.transitions.create(['background-color', 'transform'], {
            duration: theme.transitions.duration.short,
        }),
        border: `2px solid ${tertiaryColor}`,
        backgroundColor: theme.palette.common.white,
        color: tertiaryColor,
        '&:hover': {
            backgroundColor: secondaryColor,
            color: theme.palette.common.white,
        },
    };

    const selectedButtonStyle = {
        ...buttonStyle,
        backgroundColor: primaryColor,
        color: theme.palette.common.white,
        border: `2px solid ${primaryColor}`,
    };

    const addUserIngredient = (ingredient) => {
        if (!userIngredients.includes(ingredient)) {
            dispatch({ type: 'ADD_USER_INGREDIENT', payload: { item: ingredient, currentIngredients: userIngredients } });
        }
    };

    const removeUserIngredient = (ingredient) => {
        dispatch({ type: 'REMOVE_USER_INGREDIENT', payload: { item: ingredient, currentIngredients: userIngredients } });
    };

    return (
            <div className='user-pantry__wrapper max-width-1200 justify-center align-center' style={{ padding: theme.spacing(2), }}>
                <h2 className='user-pantry__subheading color-black'>Pantry</h2>
                {Object.keys(sections).map((section, idx) => (
                    <div key={idx}>
                        <Accordion className='pantry-accordion' expanded={expandedSection === section} onChange={() => toggleExpandedSection(section)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className='user-pantry__section-heading bold'>
                                    {section.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='display-flex flex-wrap' style={{ gap: theme.spacing(1) }}>
                                    {sections[section].map((item, index) => {
                                        const isSelected = userIngredients.includes(item); // Check if item is in userIngredients
                                        return (
                                            <Button
                                                key={index}
                                                variant="contained"
                                                style={isSelected ? selectedButtonStyle : buttonStyle}
                                                onClick={() => isSelected ? removeUserIngredient(item) : addUserIngredient(item)} // Use the updated functions
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.style.transform = 'scale(1.08)';
                                                    e.currentTarget.style.boxShadow = theme.shadows[4];
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.currentTarget.style.transform = isSelected ? 'scale(1.05)' : 'scale(1)';
                                                    e.currentTarget.style.boxShadow = theme.shadows[2];
                                                }}>{item}</Button>);
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>))}
            </div>
    );
}
export default Pantry;